import { useEffect, useRef } from 'react'

class DropZoneHeight {
  static HEIGHT_THRESHOLD = 5;
  static MARGIN = 8;  // 8 compensates for bottom margin my-2
  static MIN_PRACTICAL_HEIGHT = 210;  // 3.5x size of a typical task card
}

export default function TaskDropZone({ children, className, provided, style }) {
  const zoneRef = useRef(null);
  const setRef = (ref) => {  // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/using-inner-ref.md
    zoneRef.current = ref;  // keep a reference to the dom ref as an instance property
    provided.innerRef(ref);  // give the dom ref to react-beautiful-dnd
  }

  // BUCKET HEIGHT
  const handleResize = (ref) => {
    if (!ref.current) return;
    const maxHeight = window.innerHeight - ref.current.getBoundingClientRect().top - DropZoneHeight.MARGIN;
    ref.current.style.maxHeight = `${Math.max.apply(null, [maxHeight, DropZoneHeight.MIN_PRACTICAL_HEIGHT])}px`;
    ref.current.style.overflowY = ref.current.getBoundingClientRect().height < (maxHeight - DropZoneHeight.HEIGHT_THRESHOLD) ? 'hidden' : 'scroll';
  }

  useEffect(() => {
    if (zoneRef.current) handleResize(zoneRef);
  }, [zoneRef.current]);

  useEffect(() => {
    const fn = () => handleResize(zoneRef);
    window.addEventListener('resize', fn);
    return () => window.removeEventListener('resize', fn);
  }, []);

  useEffect(() => {
    const fn = () => handleResize(zoneRef);
    window.screen.orientation.addEventListener('change', fn);
    return () => window.screen.orientation.removeEventListener('change', fn);
  }, []);

  return (
    <div
      className={className}
      style={style}
      ref={setRef}
      {...provided.droppableProps}
    >
      {children}
    </div>
  )
}